import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import { rhythm } from '../utils/typography'
import { themeStyles, colors, presets, metrics } from '../utils/theme'
import _ from 'lodash'


const styles = {
}

const ThankYou = ({ data, location }) => {
  return (
    <Layout>
      <div css={[themeStyles.contentWidth, {textAlign: 'center', backgroundColor: colors.lightGrey}]}>
        <div css={[themeStyles.textPadding, {display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}]}>
          <h1>Thank You!</h1>
          <div css={[themeStyles.textPadding, {backgroundColor: colors.gold, padding: metrics.defaultPadding, color: colors.white}]}>
            Thanks! We'll be in touch.
            </div>
            <Link to="/" style={{ marginTop: '20px' }}>Back to Home Page</Link>
          <div style={{ marginBottom: '25vh' }}></div>
        </div>
      </div>
    </Layout>
  )
}

export default ThankYou
